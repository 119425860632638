const Scheduled_Pickup = "Sch.PU";
const Scheduled_Delivery = "Sch.Del.";
const Expedites = "Expedites";
const InTransits = "InTransits";
const PartNo = "PartNo";
const SaveHandlingUnitErrorMessage = "The handling units for this consol shipment cannot be adjusted because it has left the shipper.";
const HU_Issue_With_Order = "There was an issue with updating this Order. Action aborted!";   
const HU_Order_Has_Checked_In = "This Order Has Been Checked In and Its Products Can No Longer Be Modified.";
const HU_Order_Has_Confirmed = "This Order Has Been Confirmed and Its Products Can No Longer Be Modified.";
const HU_Dimension_Data_Is_Invalid = "The Given Dimension Data is Invalid. All 4 Dimensions Cannot Have a Value Greater Than Zero.";
const HU_NoUser_Associated_With_Product = "No User Was Associated With This Product Data Update.";
const HU_Update_Requested_Was_Invalid = "The Order Handling Unit Data Update Requested Was Invalid.";
const HU_Atleast_One_HandlingUnit_Exist = "At least One Handling Unit (HU) Must Exist on the Order. The HU Could not be Removed.";

const Shipment_Tile_Status = {
    Picked_Up: "Picked Up",
    Scheduled: "Scheduled",
    Late: "Late",
    Already_Picked_Up: "Already Picked Up",
    Late_Pick_Up: "Late Pick Up",
    Pickup_Scheduled: "Pickup Scheduled",
    On_Time:"On Time",
    Delivery_Scheduled: "Delivery Scheduled"
}

const Order_Status = {
    Closed: "Closed",
    Ordered_Not_Used: "Ordered Not Used",
    In_Route: "In Route",
    Delivered: "Delivered",
    Cancelled: "Cancelled",
    Shipment_Created: "Shipment Created",
    Assigned: "Assigned"
}

const Shipment_Type_Status = {
    In_Route_To_Process_Hub: "In Route To",
    Arrived_At_Process_Hub: "Arrived At",
    Confirmed_At_Process_Hub: "Confirmed At",
    Pending_Departure_Process_Hub: "Pending Departure",

    Dispatched_Pickup_Carrier: "Dispatched Pickup Carrier",
    Pending_PickUp: "Pending Pick Up",
    In_Route_To_Consol_Hub: "In Route To Consol Hub",
    Arrived_At_Consol_Hub: "Arrived At Consol Hub",
    Pending_Departure_Consol_Hub: "Pending Departure Consol Hub",
    Out_For_Delivery: "Out For Delivery",
    Delivered: "Delivered",
    Ordered_Not_Used: "Ordered Not Used",
    Closed: "Closed",
    Arrived_At_Intermediate_Hub: "Arrived At Intermediate Hub",
    Pending_Departure_Intermediate_Hub: "Pending Departure Intermediate Hub",
    In_Route_To_Intermediate_Hub: "In Route To Intermediate Hub"
}

const ROLES = {
    INTERNAL_ROLE_ADMINISTRATOR : "Administrator",
    INTERNAL_ROLE_DEVELOPER :  "Developer",
    INTERNAL_ROLE_USER :  "User",
    INTERNAL_ROLE_MANAGEMENT :  "Management",
    INTERNAL_ROLE_ACCOUNTING :  "Accounting",
    INTERNAL_ROLE_ACCOUNTING_MANAGER :  "Accounting Manager",
    INTERNAL_ROLE_PLANNING_MANANGER :  "Planning Manager",
    INTERNAL_ROLE_ENGINEERING :  "Analysis/Engineering",
    INTERNAL_ROLE_PROCUREMENT :  "Procurement",
    INTERNAL_ROLE_FM_MANAGER :  "FM Manager",
    INTERNAL_ROLE_FM_ACCOUNT_REP :  "FM Account Rep",
    EXTERNAL_ROLE_CARRIER : "Carrier",
    EXTERNAL_ROLE_EDI : "EDI",
    EXTERNAL_ROLE_CUSTOMER : "Customer",
    EXTERNAL_ROLE_SUPPLIER : "Supplier",
    EXTERNAL_ROLE_EXTERNAL_DRIVER : "ExternalDriver",
    EXTERNAL_ROLE_OPS_MANAGER : "Ops Manager"
}

export default {
    Scheduled_Pickup,
    Scheduled_Delivery,
    Expedites,
    InTransits,
    PartNo,
    SaveHandlingUnitErrorMessage,
    Shipment_Tile_Status,
    Order_Status,
    Shipment_Type_Status,
    ROLES,
    HU_Issue_With_Order,
    HU_Order_Has_Checked_In,
    HU_Order_Has_Confirmed,
    HU_Dimension_Data_Is_Invalid,
    HU_NoUser_Associated_With_Product,
    HU_Update_Requested_Was_Invalid,
    HU_Atleast_One_HandlingUnit_Exist
}