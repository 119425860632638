import PTRUI from 'protrans-react-ui';
import { TileInfo } from '../common/_types/tiles-types';
import { IState, TActions } from '../Store';

const useStore = () => {
    const [State, Store, Util] = PTRUI.useStore<IState, TActions>();
    const localizeData = State.localizeData;
    const typeView = State.typeView;
    const userData = State.userData;
    const customerOrSupplier = State.customerOrSupplier;
    const currentUser = State.currentUser;
    const proData = State.proData;
    const landingPageSearchText = State.landingPageSearchText;
    const landingPageSearchPartText = State.landingPageSearchPartText;
    const selectedBillTo = State.selectedBillTo;
    const selectedShipTo = State.selectedShipTo;
    const tileSidebarInfo = State.tileSidebarInfo;
    const fireGetUserInfo = State.fireGetUserInfo;
    const isCustomerSelected = State.isCustomerSelected;
    const isBillToSelected = State.isBillToSelected;
    const selectedBillToDetails = State.selectedBillToDetails;
    const selectedCutomerDetails = State.selectedCutomerDetails;
    const showCreateOrderAndSRIcon = State.showCreateOrderAndSRIcon;
    const CustomerModalState = State.CustomerModalState;

    const updateLocalizeData = (localizeData: any) => {
        if (localizeData != undefined) {
            Store.update(Util.actions.updateLocalizeData, localizeData);
        } else {
            console.error('Language data is undefined.');
        }
    }

    const updateTypeView = (typeView: string) => {
        Store.update(Util.actions.updateTypeView, typeView);
    }

    const updateUserData = (userData: any) => {
        Store.update(Util.actions.updateUserData, userData);
    }

    const updateIsLogin = (isUserValid: boolean) => {
        Store.update(Util.actions.updateIsLogin, isUserValid);
    }

    const updateCurrentUser = (currentUser: string) => {
        Store.update(Util.actions.updateCurrentUser, currentUser);
    }

    const updateProOrPack = (proOrPack: any) => {
        Store.update(Util.actions.updateProOrPack, proOrPack);
    }

    const updateLandingPageSearchText = (landingPageSearchText: string) => {
        Store.update(Util.actions.updateLandingPageSearchText, landingPageSearchText);
    }

    const updateLandingPageSearchPartText = (landingPageSearchPartText: string) => {
        Store.update(Util.actions.updateLandingPageSearchPartText, landingPageSearchPartText);
    }

    const updateSelectedBillTo = (selectedShipFrom:any) => {
        Store.update(Util.actions.updateSelectedBillTo,selectedShipFrom);
    }

    const updateSelectedShipTo = (selectedShipTo:any) => {
        Store.update(Util.actions.updateSelectedShipTo, selectedShipTo);
    }

    const updateTileSidebarInfo = (tileSidebarInfo:TileInfo[]) => {
        Store.update(Util.actions.updateTileSidebarInfo, tileSidebarInfo);
    };

    const updateFireGetUserInfo = (fireGetUserInfo:boolean) => {
        Store.update(Util.actions.updateFireGetUserInfo, fireGetUserInfo);
    };
   

    const updateIsCustomerSelected = (isCustomerSelected: any) => {
        if (isCustomerSelected != undefined) {
            Store.update(Util.actions.updateIsCustomerSelected, isCustomerSelected);
        } else {
            console.error('isCustomerSelected is undefined.');
        }
    }

    const updateIsBillToSelected = (isBillToSelected: any) => {
        if (isBillToSelected != undefined) {
            Store.update(Util.actions.updateIsBillToSelected, isBillToSelected);
        } else {
            console.error('isBillToSelected is undefined.');
        }
    }

    const updateSelectedBillToDetails = (selectedBillToDetails:any) => {
        Store.update(Util.actions.updateSelectedBillToDetails, selectedBillToDetails);
    }

    const updateSelectedCustomerDetails = (selectedCutomerDetails:any)=>{
        Store.update(Util.actions.updateSelectedCustomerDetails, selectedCutomerDetails);
    }

    const updateShowCreateOrderAndSRIcon = (showCreateOrderAndSRIcon:boolean) => {
        Store.update(Util.actions.updateShowCreateOrderAndSRIcon, showCreateOrderAndSRIcon);
    };

    const updateCustomerModalState = (CustomerModalState:boolean) => {
        Store.update(Util.actions.updateCustomerModalState, CustomerModalState);
    };

    return {
        updateLocalizeData,
        updateTypeView,
        updateUserData,
        updateIsLogin,
        updateCurrentUser,
        updateProOrPack,
        updateLandingPageSearchText,
        updateLandingPageSearchPartText,
        updateSelectedBillTo,
        updateSelectedShipTo,
        updateTileSidebarInfo,
        updateFireGetUserInfo,
        updateIsBillToSelected,
        updateIsCustomerSelected,
        updateSelectedBillToDetails,
        updateSelectedCustomerDetails,
        updateShowCreateOrderAndSRIcon,
        updateCustomerModalState,
        localizeData,
        typeView,
        userData,
        customerOrSupplier,
        currentUser,
        proData,
        landingPageSearchText,
        landingPageSearchPartText,
        selectedBillTo,
        selectedShipTo,
        tileSidebarInfo,
        fireGetUserInfo,
        isBillToSelected,
        isCustomerSelected,
        selectedBillToDetails,
        selectedCutomerDetails,
        showCreateOrderAndSRIcon,
        CustomerModalState
    }
}

export default useStore;