import React from 'react';
import PTRUI from 'protrans-react-ui';
import useStore from './use-store';
import { default as locale_en } from '../i18n/en';

const useI18N = () => {
    const store = useStore();
    const { localeKey, setLocaleKey, translateThis } = PTRUI.useI18N();
    const [localizeData, setLocalizeData] = React.useState(locale_en);
    const message = PTRUI.useInAppMessage();

    const fetchLocalizeData = (key: 'en' | 'es') => {
        if (key === 'en') {
            setTimeout(() => {
                store.updateLocalizeData(locale_en);
                message.createSuccess('Language set to English');
            }, 1000);
        }
        if (key === 'es') {
            setTimeout(async () => {
                const { default: locale_es } = await import('../i18n/es');
                store.updateLocalizeData(locale_es);
                message.createSuccess('Idioma configurado en español');
            }, 1000);
        }
    }

    React.useEffect(() => {
        setLocalizeData(store.localizeData);
    }, [store.localizeData]);

    return {
        localeKey,
        setLocaleKey,
        translateThis,
        localizeData,
        fetchLocalizeData
    }
}

export default useI18N;