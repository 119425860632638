import React from 'react';
import Navigation from './Navigation';
import Routes from './Routes';
import Store from './Store';
import Config from './Config';
import Startup from './Startup';
import PTRUI from 'protrans-react-ui';
import './styles.global.scss';

function App() {

  return (
    <PTRUI.App
      basename={"/"}
      config={Config}
      startup={Startup}
      navigation={Navigation}
      routes={Routes}
      store={Store}
    />
  );
}

export default App;




