import PTRUI from 'protrans-react-ui';
import { TileInfo } from './common/_types/tiles-types';
import { default as locale_en } from './i18n/en';
import I18N from './i18n/interface';

const actionKeys = {
    ['updateLocalizeData']: 'updateLocalizeData',
    ['updateUserData']: 'updateUserData',
    ['updateIsLogin']: 'updateIsLogin',
    ['updateCurrentUser']: 'updateCurrentUser',
    ['updateProOrPack']: 'updateProOrPack',
    ['updateTypeView']: 'updateTypeView',
    ['updateLandingPageSearchText']: 'updateLandingPageSearchText',
    ['updateLandingPageSearchPartText']: 'updateLandingPageSearchPartText',
    ['updateSelectedBillTo'] : 'updateSelectedBillTo',
    ['updateSelectedShipTo']:'updateSelectedShipTo',
    ['updateTileSidebarInfo']:'updateTileSidebarInfo',
    ['updateFireGetUserInfo']:'updateFireGetUserInfo',
    ['updateIsCustomerSelected']: 'updateIsCustomerSelected',
    ['updateIsBillToSelected']: 'updateIsBillToSelected',
    ['updateSelectedBillToDetails']: 'updateSelectedBillToDetails',
    ['updateSelectedCustomerDetails']: 'updateSelectedCustomerDetails',
    ['updateShowCreateOrderAndSRIcon']: 'updateShowCreateOrderAndSRIcon',
    ['updateCustomerModalState']: 'updateCustomerModalState'
};

export type TActions = typeof actionKeys;

export interface IState {
    localizeData: I18N;
    userData: any;
    customerOrSupplier: boolean;
    currentUser : string;
    proData : any;
    typeView: string;
    landingPageSearchText: string;
    landingPageSearchPartText: string;
    selectedBillTo:any;
    selectedShipTo:any;
    tileSidebarInfo:TileInfo[];
    fireGetUserInfo : boolean;
    isBillToSelected: boolean;
    isCustomerSelected: boolean;
    selectedBillToDetails: any;
    selectedCutomerDetails: any;
    showCreateOrderAndSRIcon: boolean;
    CustomerModalState:boolean;
}

const Store = PTRUI.createStore([
    {
        action: actionKeys.updateLocalizeData,
        state: {
            localizeData: locale_en
        },features: {
            persist: true
        }
    },
    {
        action: actionKeys.updateTypeView,
        state: {
            typeView: '1'
        },
        features: {
            persist: false
        }
    },
    {
        action: actionKeys.updateUserData,
        state: {
            userData: undefined
        },
        features: {
            persist: true
        }
    },
    {
        action: actionKeys.updateIsLogin,
        state: {
            customerOrSupplier: true
        },
        features: {
            persist: true
        }
    },
    {
        action: actionKeys.updateCurrentUser,
        state: {
            currentUser: undefined
        },
        features: {
            persist: true
        }
    },
    {
        action: actionKeys.updateProOrPack,
        state: {
            proData: ""
        }
    },
    {
        action: actionKeys.updateLandingPageSearchText,
        state: {
            landingPageSearchText: ""
        },
        features: {
            persist: false
        }
    },
    {
        action: actionKeys.updateLandingPageSearchPartText,
        state: {
            landingPageSearchPartText: ""
        },
        features: {
            persist: false
        }
    },
    {
        action: actionKeys.updateSelectedBillTo,
        state:{
            selectedBillTo:undefined
        },features:{
            persist:false
        }
    },
    {
        action: actionKeys.updateSelectedShipTo,
        state:{
            selectedShipTo:undefined
        },features:{
            persist:false
        }
    },
    {
        action: actionKeys.updateTileSidebarInfo,
        state:{
            tileSidebarInfo:[]
        },features:{ 
            persist:true 
        }
    },
    {
        action: actionKeys.updateFireGetUserInfo,
        state:{
            fireGetUserInfo : false
        },features:{ 
            persist:false 
        }
    },
    {
        action: actionKeys.updateIsBillToSelected,
        state: {
            isBillToSelected: false,
        }, features: {
            persist: false
        }
    },
    {
        action: actionKeys.updateIsCustomerSelected,
        state: {
            isCustomerSelected: false,
        }, features: {
            persist: false
        }
    },
    {
        action: actionKeys.updateSelectedBillToDetails,
        state: {
            selectedBillToDetails: undefined
        }, features: {
            persist: false
        }
    },
    {
        action: actionKeys.updateSelectedCustomerDetails,
        state: {
            selectedCutomerDetails: undefined
        }, features: {
            persist: false
        }
    },
    {
        action: actionKeys.updateShowCreateOrderAndSRIcon,
        state:{
            showCreateOrderAndSRIcon : false
        },features:{ 
            persist : false 
        }
    },
    {
        action: actionKeys.updateCustomerModalState,
        state:{
            CustomerModalState : false
        },features:{ 
            persist : false 
        }
    }
]);

export default Store;

