import React, { useEffect, useState } from 'react';
import PTRUI from 'protrans-react-ui';
import useLocalize from './hooks/use-i18n';
import useStore from './hooks/use-store';
import { urlConsts } from './common/_constants';
import { useLogin } from 'protrans-react-ui/lib/hooks';

export default function StartUp() {
    const store = useStore();
    const {user} = useLogin()
    const login = PTRUI.useLogin();
    const message = PTRUI.useInAppMessage();
    const { fetchLocalizeData, localeKey } = useLocalize();
    const [fireGetUserInfo, setFireGetUserInfo] = useState(false);

    const { post: postGetUser, response : responseGetUser , error : errorGetUser } = PTRUI.useFetch<{ success : boolean, data: any, message : string }>(``, 
    { 
        profile:'auth',
        responseType :'json',
        bodyType: 'json'  
    });

    const { get: getIPIPro, response : responseGetIPIPro } = PTRUI.useFetch<{ success : boolean, data: any }>(``, 
    { 
        profile:'auth',
        responseType :'json',
        bodyType: 'json'  
    });

    useEffect(()=>{
        console.log(user)
        if(!user?.id){
            store.updateUserData({
                shipFroms:[],
                legalName:'',
                customers:[],
                suppliers:[],
                shipTos: [],
                portalConfiguration: {
                    CanViewRateShop : false,
                    EnableTrackingMap : false,
                    CanViewChargeRates : false,
                    CanViewCostRates : false
                }
            }); 
            store.updateCurrentUser('');
        }
    },[user])
    
    const GetIPIPro = (displayId: string) => {
        if (window.gbl_ShowIPITile && ( displayId != "" || displayId != undefined))
            getIPIPro(urlConsts.GET_IPI_PRO + '?customerId=' + displayId);
    }

    PTRUI.useNonInitialEffect(() => {
        if (responseGetIPIPro && responseGetIPIPro?.success)
            store.updateProOrPack(responseGetIPIPro?.data == 1 ? "Pro" : "Pac");
    }, [responseGetIPIPro]);

    PTRUI.useNonInitialEffect(()=>{
        if(responseGetUser){
            if(!responseGetUser.success){
                message.createInfo(responseGetUser?.message, { dismiss : true });
                login.openLogoutModal();
            }
            if (responseGetUser && responseGetUser.data && responseGetUser.success) { 
                let userInfo = (responseGetUser.data as any);
                const rolesArray: any[] = userInfo?.userRolesArray;
                const filteredRoles: any[] = rolesArray.filter((x: any) => x == "Customer" || x == "Supplier");
                 if(filteredRoles.length > 0){
                    if(store.currentUser !== userInfo?.userName)
                    {
                        store.updateTypeView('1');
                        store.updateSelectedBillTo({ value : '', text : '' }); 
                        store.updateSelectedShipTo({ value : '', text : '' });
                    }
                    store.updateUserData(userInfo); 
                    store.updateCurrentUser(userInfo?.userName || '');
                    login.setUser({
                        id : userInfo?.userId || 0,
                        firstName: userInfo?.userFirstName || '',
                        lastName: userInfo?.userLastName || '',
                        email: userInfo?.userEmail || '',
                        userName: userInfo?.userName || '',
                        profilePic: userInfo?.ProfilePic || ''
                    });
                    store.updateShowCreateOrderAndSRIcon(userInfo?.clientBillTosAllowedToCreateOrders?.filter((x: any) => x.AllowCreateOrder == true).length > 0
                        || userInfo?.clientBillTosAllowedToCreateOrders?.filter((x: any) => x.AllowShipmentRequest == true).length > 0
                        || userInfo?.supplierBillTosAllowedToCreateOrders.length > 0);
                    GetIPIPro(userInfo?.customers[0]?.DisplayId ?? 0);       
                    store.updateIsLogin(true);      
                    login.goToApp();
                    if(filteredRoles.filter((x:any)=>x == "Customer").length > 0){
                        store.updateSelectedCustomerDetails(userInfo?.customers[0]);
                        store.updateIsCustomerSelected(true);
                    }
                }
                else{
                     store.updateIsLogin(false);
                }
            }
        }
    },[responseGetUser]);
    
    //updates localize data
    PTRUI.useNonInitialEffect(() => {
        fetchLocalizeData(localeKey);

    }, [localeKey]);
 
    PTRUI.useNonInitialEffect(()=>{
        if(login.token && fireGetUserInfo)
        {
            setFireGetUserInfo(false);
            setTimeout(()=>{ 
                postGetUser('/LogOn/Account/GetUserInfo');
            },3000);
        }
    },[login.token, fireGetUserInfo]);

    useEffect(()=>{
        login.setUser({
            id : '',
            firstName : '',
            lastName : '',
            userName : '',
            email : ''
        }); 
        setFireGetUserInfo(true);
        store.updateIsLogin(true);
    },[]);

    return null;
}