import I18N from './interface';

const en: I18N = {
    title : "Customer Supplier Portal",
    loading : "Loading...",
    landingPage : {
        tiles : {
            createOrder : {
                title : "Create Order",
            },
            logisticsPlans : {
                title : "Logistics Plans",
            },
            productionPlanning : {
                title : "Production Planning",
                subTiles : {
                    all : "All",
                    productManagement : "Product Management",
                    packageManagement : "Package Management",
                    missingData : "Missing Data"
                }
            },
            bussinessIntelligence : {
                title : "Business Intelligence",
            },
            ipi : {
                title : "IPI",
            },
            scheduledPickups : {
                title : "Scheduled Pickups",
                subTiles : {
                    total : "Total",
                    scheduled : "Scheduled",
                    onTime : "On Time",
                    late : "Late"
                }
            },
            inTransit : {
                title : "In Transit",
                subTiles : {
                    total : "Total",
                    onTime : "On Time",
                    late : "Late"
                }
            },
            scheduledDeliveries : {
                title : "Scheduled Deliveries",
                subTiles : {
                    total : "Total",
                    onTime : "On Time",
                    late : "Late",
                    delivered : "Delivered"
                }
            },
            expedites : {
                title : "Expedites",
                subTiles : {
                    total : "Total",
                    scheduled : "Scheduled",
                    delivered : "Delivered",
                    late : "Late"
                }
            },
            rateShop : {
                title : "Rate Shop"
            },
            heatMap: {
                title: "Heatmap"
            },
            createOrderAndSR: {
                title: "Shipment Request/Create Order"
            }
        },
        typeView:{
            today:'Today',
            tomorrow:'Tomorrow'
        },
        all:'All',
        billTo:'Bill To',
        shipTo:'Ship To',
        date:'Date',
        search : "Search",
        loginInvalidMessage: "This user does not have a customer or supplier role."
    },grids:{
        breadcrumbs:{
            all:"All",
            ontime:"On Time",
            pending:"Pick Up",
            late:"Late",
            delivered: "Delivered",
            scheduled:"Scheduled"
        },
        confirmPULInfo:"Confirm PUL can not be processed due to shipment not matching Confirm Prerequisites.",
        confirmPULDisableInfo: "Confirm PUL Disable Info:",
        confirmPUL:"Confirm PUL",
        rejectPUL:"Reject PUL",
        addBOL:"Add BOL",
        PULConfirmationSend:"The PUL Confirmation Send For Shipments: ",
        PULRejectionSend:"The PUL Rejection Send For Shipments: ",
        wasSuccessful:" Was Successful.",
        processing:"Processing...",
        pleaseSelectPlanToConfirm:"Please select plan(s) to Confirm",
        searchParts:"Search Parts",
        selectOneElement:"Select just one element",
        columns:{
            id:"ID",
            info:"INFO",
            shipment:"SHIPMENT",
            service:"SERVICE",
            pickupDateTime:"PICKUP DATE/TIME",
            dueDateTime:"DUE DATE/TIME",
            etaDateTime:"ETA DATE/TIME",
            deliveringCarrier:"DELIVERING CARRIER",
            status:"STATUS",
            lastKnownLocation:"LAST KNOWN LOCATION",
            pieces:"PIECES",
            weightLbs:"WEIGHT LBS",
            shipFrom:"SHIP FROM",
            shipTo:"SHIP TO",
            references:"REFERENCES",
            customer:"CUSTOMER",
            expediteContact:"EXPEDITE CONTACT",
            pickupCarrier:"PICKUP CARRIER",
            mode:"MODE",
            contact:"CONTACT"
        },
        titleShipmentDetails:"View/Update Shipment Details"
    },shipmentDetails:{
        document:"Document",
        shipment:"Shipment",
        note:"Note",
        addNote:"Add Note",
        addNotes:"Add Notes",
        attachReferenceType:"Attach Reference Type",
        shipmentDetail:"SHIPMENT DETAIL",
        print:"Print",
        shipmentStatus:"Shipment Status",        
        shipmentInfo:"SHIPMENT INFO",
        shipFrom:"SHIP FROM",
        shipTo:"SHIP TO",
        customer:"CUSTOMER",
        requestedBy:"REQUESTED BY",
        originalPickupDay:"ORIGINAL PICKUP DAY",
        dueDateOrAppoinment:"DUE DATE or APPOINTMENT",
        etaDueDate:"ETA DUE DATE",
        processing:"Processing...",
        noteFieldRequired:"The Note field is required.",
        noteAlreadySaved:"This Note is already saved",
        dataSavedSuccesfully:"Data saved successfully",
        noNoteComment:"No Note Comment Was Associated With This Shipment Note Update.",
        noFileUpload:"No File upload or invalid file type.",
        fileExceedLimit:"Unable to upload file. File exceeds 10 MB.",
        shipmentTileStatus:{
            pickUp:"Picked Up",
            scheduled:"Scheduled",
            late:"Late",
            alreadyPickUp:"Already Picked Up",
            latePickUp:"Late Pick Up",
            pickUpScheduled:"Pickup Scheduled",
            onTime:"On Time",
            deliveryScheduled : "Delivery Scheduled"
        },
        orderStatus:{
            closed:"Closed",
            orderedNotUsed:"Ordered Not Used",
            inRoute:"In Route",
            delivered:"Delivered",
            cancelled:"Cancelled",
            shipmentCreated:"Shipment Created",
            assigned:"Assigned"
        },
        shipmentTypeStatus:{
            inRouteToProcessHub : "In Route To",
            arrivedAtProcessHub : "Arrived At",
            confirmedAtProcessHub : "Confirmed At",
            pendingDepartureProcessHub : "Pending Departure",
            dispatchedPickupCarrier : "Dispatched Pickup Carrier",
            inRouteToConsolHub : "In Route To Consol Hub",
            arrivedAtConsolHub : "Arrived At Consol Hub",
            pendingDepartureConsolHub : "Pending Departure Consol Hub",               
            outForDelivery : "Out For Delivery",
            delivered : "Delivered",
            orderedNotUsed : "Ordered Not Used",
            closed : "Closed",
            arrivedAtIntermediateHub : "Arrived At Intermediate Hub",
            pendingDepartureIntermediateHub : "Pending Departure Intermediate Hub",
            inRouteToIntermediateHub : "In Route To Intermediate Hub",
            pendingPickUp: "Pending Pick Up"
        },
        handlingUnitGrid:{
            title:"HANDLINGS UNIT",
            line:"LINE",
            pieces:"Quantity",
            weight:"Weight",
            length:"Length",
            width:"Width",
            height:"Height",
            diameter:"Diameter",
            stackFactor:"Stack Factor",
            loadRestriction:"Load Restriction",
            nmfcCode:"Nmfc Code",
            validateNmfcCode:"Special Characters are not allowed",
            requiredNmfcCode: "NMFC Code required",
            nmfcClass:"Nmfc Class",
            options:"Options",
            shipment:"Shipment: ",
            isAssociated:"is associated with an invoice.  Please contact the AR Team with your changes.",
            someOf:"Some of the selected barcodes are assigned to a load: ",
            deleteAborted:". Delete aborted.",
            processing:"Processing...",
            savedDataSuccesfully:"Data saved successfully",
            dataRemovedSuccesfully:"Data removed successfully",
            handlingUnitErrorMessage: "The handling units for this consol shipment cannot be adjusted because it has left the shipper.",
            handlingUnitOrderTotalErrorMessage: " The Order Totals Are Invalid. They Must Be Greater Than Zero",
            validationEmptyFields:"Please enter the values for Length, Width, Height or Diameter",
            validationEmptyDiameterVolume:"Height and Diameter are required",
            validationEmptyVolume:"Length, Width and Height are required",
            deleteRow:'Are you sure do you want to delete this?',
            confirm:'Confirm',
            cancel:'Cancel',
            validationGreaterThanZero:'The Given Dimension Data is Invalid. All 4 Dimensions Cannot Have a Value Greater Than Zero.',
            save:"Save",
            delete:"Delete",
            edit:"Edit",
            HUIssueWithOrder :"There was an issue with updating this Order. Action aborted!",
            HUOrderHasCheckedIn:"This Order Has Been Checked In and Its Products Can No Longer Be Modified.",
            HUOrderHasConfirmed:"This Order Has Been Confirmed and Its Products Can No Longer Be Modified.",
            HUDimensionDataIsInvalid:"The Given Dimension Data is Invalid. All 4 Dimensions Cannot Have a Value Greater Than Zero.",
            HUNoUserAssociatedWithProduct:"No User Was Associated With This Product Data Update.",
            HUUpdateRequestedWasInvalid:"The Order Handling Unit Data Update Requested Was Invalid.",
            HUAtleastOneHandlingUnitExist:"At least One Handling Unit (HU) Must Exist on the Order. The HU Could not be Removed.",
            validationMaxVolume:"The Volume of the Handling Unit is Greater Than the Maximum Volume Allowed.",
            userNotAllowedEdit:"Cannot modify Handling Unit at this time. Please reach out to your ProTrans SCM."
        },partsPackingGrid:{
            title:"PARTS & PACKAGING",
            partNumber:"Part Number",
            expectedQuantity:"Expected Quantity",
            actualQuantity:"Actual Quantity",
            primaryPackageId:"Primary Package Id",
            secondaryPackageId:"Secondary Package Id",
            status:"Status",
            confirmedBy:"Confirmed By"
        },curtomerRelatedInfoGrid:{
            title:"CUSTOMER REQUESTED INFORMATION",
            referenceLabel:"Reference Label",
            refereneValue:"Reference Value",
            isRequired:"Customer Requested",
            attached:"Attached",
            options:"Options",
            alreadyExists:"already exists.",
            processing:"Processing...",
            dataSaved:"Data saved successfully",
            addReference:"Add Reference",
            pleaseSelectReferenceType:"Please select the Reference Label",
            pleaseAddValueReferenceType:"Please add the value of Reference Value"
        },eventLogGrid:{
            title:"EVENT LOG",
            date:"Date",
            time:"Time",
            activity:"Activity",
            location:"Location"
        },shipmentNotesGrid:{
            title:"NOTES",
            date:"Date",
            time:"Time",
            communicationDetail:"Communication Detail",
            location:"Created By"
        }
    },papperwork:{
        papperwork:"Paperwork",
        printBOL:"Print BOL",
        printPUL:"Print PUL",
        printShippingLabel:"Print Shipping Label",
        selectOne:"Please select one row of the grid."
    },ProductionPlanningGrid:{
        ProductNumber: "Product Number",
        Search: "Search",
        SearchByShipFromName : "Search By ShipFrom Name",
        SearchByShipToName: "Search By ShipTo Name",
        Account: "Account",
        SearchByAccountName: "Search by Account Name",
        TotalShippedQty: "Total Shipped Qty",
        ShipFrom: "ShipFrom",
        ShipTo: "ShipTo",
        Release : "Release", 
        Legend : "Legend", 
        OnTime : "OnTime",
        Late : "Late",
        Delivered : "Delivered",
        ProductionPlanningGridSearch : "Production Planning Grid Search",
        columns:{
            ProductID : "Product ID",
            ShipFromName: "ShipFrom Name",
            ShipFromCode: "ShipFrom Code",
            ShipToName: "ShipTo Name",
            ShipToCode: "ShipTo Code",
            InTransit: "In Transit",
            CurrentWeek: "Current Week",
            NextWeek: "Next Week",
            Shipment: "Shipment",
            PickupDate: "Pickup Date",
            DueDate: "Due Date",
            ETA: "ETA",
            QTY: "QTY"
        }
    },customRowReferenceType:{
        processing:"Processing...",
        updateShipmentReferences : "Update Shipment References",
        theFileUpload:"The file upload is being processed...",
        alreadyExists:"already exists.",
        addBOL:"Add BOL",
        dataRemovedSuccesfully:"Data removed successfully",
        dataSavedSuccesfully:"Data saved successfully",
        referenceTypeIsRequired:"The value reference is required",
        referenceLabel:"Reference Label",
        refereneValue:"Reference Value",
        addReference:"Add Reference",
        required:"Required",
        optional:"Optional",
        attached:"Attached",
        fileAttached:"File attached successfully",
        options:"Options",
        attachFile:"Attach File",
        attachAnotherFile:"Attach Another File",
        viewFile:"View File",
        isRequired:"Customer Requested",
        updateShipment:"Update Shipment References",
        save:"Save",
        edit:"Edit",
        delete:"Delete",
        cancel:"Cancel",
        confirm:"Confirm",
        errorEdit:"Error editing data, please try again.",
        errorNoFileSave:"Error saving data, please upload at least one file.",
        errorDeleteRequired:"Error deleting, you can't delete a required Reference Type.",
        pleaseSaveCancelEditedRow:"Please save or cancel edited row.",
        invalidReferenceType:"Invalid reference value.",
        pleaseAddValueReferenceType:"Please add the value of Reference Type",
        validateNmfcCodeAndDescription:"Special Characters are not allowed",
        requiredNmfmCode:"NMFC Code is required",
        requiredNmfmDescription:"NMFC Description is required",
        deleteRecord:"Are you sure do you want to delete this?",
    },
    createOrder : {
        loading : "Loading.....",
        messages : {
            m1: "You selected a holiday as your Pickup day. This has been changed to: ",
            m2: "Submitted",
            m3: "The 'Shipper - Ready Date' field is required.",
            m4: "The 'Shipper - Ready Time' field is required(valid format from 00:00 to 23:59).",
            m5: "The 'Shipper - Close Time' field is required(valid format from 00:00 to 23:59).",
            m6: "You must confirm at least one Handling Unit.",
            m7: " Reference is required.",
            m8: "Select one option from Billing Details section.",
            m9: "Your currently selected date is a designated Lock Out day. You must either change it to an approved date or add a Lockout Authorization Number reference.",
            m10: "Validating...",
            m11: "There are some missing field that are required",
            m12: "Value for Quantity / Weight has to be greather than 0",
            m13: "Value for Length / Width / Height / Diameter cannot be cero on row #",
            m14: "File size exceeds the 4MB limit",
            m15: "invalid Dimensions of HU on row #",
            m16: "Duplicate handling unit type found, please update existing handling unit.",
            m17: "Invalid delivery appointment contact info name.",
            m18: "You entered an invalid requestor number, please change for a valid one.",
            m190: "You entered an invalid appointment contact info number, please change for a valid one.",
            m200: "You entered an invalid emergency contact number, please change for a valid one.",
            m201: "The duplicate reference type was found, please select another reference type.",
            m202: "Duplicate reference type was found or reference type was empty",
            m203: "The Volume of the Handling Unit is Greater Than the Maximum Volume Allowed.",
            m19: "Delivery Appointment email is empty or non email format",
            m20: "Inbond Number is required on row #.",
            m21: "Origin and Destination are required",
            m22 : "Shipper - Close Time must be greater than Shipper - Ready Time",
            m23:"Selected  logistic plan Id is Invalid.",
            m24: "Selected file is not valid. Only are allowed the files with the extensions .pdf .jpg .png .xls .xlsx .docx",          
            m25:"The Volume of the Handling Unit is Less Than the Minimum Volume Allowed.",
            m26:"Enter Phone or Email.",
            m27:"The Order Totals Are Invalid. They Must Be Greater Than Zero.",
            m28: "The Reference type {0} is required.",
            m29: "Please select the country for requestor phone",
            m30: "Please enter the phone number for requestor.",
            m31: "Please select the country for Delivery Appointment Contact Info phone",
            m32: "Please enter the phone for Delivery Appointment Contact.",
            m33: "Please select the country for Hazmat contact phone",
            m34: "Please enter the phone for Hazmar contact phone"
        },
        modals:{
            bOLMissingData : {
                title : 'BOL Requires...',
                header: "The BOL requires that all Handling Units have: NMFC Code, NMFC Class, and Description specified.Please correct and then Click the 'Print' button",
                columns:{
                    Id : "Id",
                    Info : "Information",
                    NmfcClass : "Nmfc Class",
                    NmfcCode : "Nmfc Code",
                    Description : "Description",
                    Options : "Options"
                } 
            },
            duplicatedOrder : {
                title : 'You should know...',
                header : "We found a Duplicate Order for this Origin-Destination picking up on the same day.",
                columns : {
                    Order : "Order",
                    PickupDate : "PickupDate",
                    Origin : "Origin",
                    Destination : "Destination",
                    TotalQuantity : "Total Quantity",
                    TotalWeight : "Total Weight",
                    Reference : "Reference"
                },
                m1 : "Just use the Shipment(s) listed above.",
                m2 : "Make my order even though there is a duplicate. This may result in double pick up charges.",
                cancelOrder : "Cancel Order",
                saveOrder : "Save Order"
            },
            saveSucess : {
                thanksYouForYourOrder : "Thank You For Your Order. An email confirmation will be sent in 10-15 minutes to ",
                orderStatus : "Order Status",
                forCustomerTracking : "For Customer Tracking",
                forCarrierPickup : "For Carrier Pickup",
                scheduledCarrier : "Scheduled Carrier",
                importantReferences : "Important References"
            }
        },
        sections  : {
            header : {
                title : 'CREATE ORDER',
                suggestRoutes : {
                    title : 'Logistic Plan Id',
                    placeHolder : 'Choose a Logistic Plan',
                    legend: 'Create an Order by Searching for a Logistic Plan ID or by entering the required fields.',
                    noData : 'There is no Logistic Plans related to the current user'
                }
            },
            requestedInfo : {
                title : '',
                requestedBy : {
                    title : 'Order Requested By',
                    placeHolder : 'Order Requested By',
                },
                requestorPhone : {
                    title : 'Requestor Phone',
                    placeHolder : 'Requestor Phone',
                },
                requestorEmail : {
                    title : 'Requestor Email',
                    placeHolder : 'Requestor Email',
                }
            },
            filterCustomerBillTo : {
                customer : {
                    title : "Customer",
                    placeHolder : "Choose a Customer",
                },
                billTo : {
                    title : "Bill To",
                    placeHolder : "Choose a Bill To",
                },
            },
            payorTerm : {
                prepaid : "Prepaid",
                collect : "Collect",
                billTo : "Bill To"
            },
		    entities : {
                title : '',
                shipFrom : 'Ship From',
                shipTo : 'Ship To',
                careOf : 'Care Of',
                billTo : 'Bill To',
                shipToCareOf : 'Ship To Care Of',
                change : 'Change'
            },
            modeServiceDetails : {
                title : 'Mode & Service Details',
                transportationMode : {
                    title : 'Transportation Mode',
                    placeHolder : 'Choose Option',
                },
                equipmentType : {
                    title : 'Equipment Type',
                    placeHolder : 'Choose Option',
                },
                truckloadService : {
                    title : 'Truckload Service',
                    placeHolder : 'Choose Option',
                }
            },
            readyDateTime : {
                title : 'Ready Date & Time',
                readyDate : {
                    title : 'Ready Date',
                    placeHolder : '',
                },
                readyTime : {
                    title : 'Ready Time',
                    placeHolder : '',
                },
                closeTime: {
                    title : 'Close Time',
                    placeHolder : '',
                }
            },	
            deliveryAppointmentContactInfo : {
                title : 'Delivery Appointment Contact Info',
                name : {
                    title : 'Name',
                    placeHolder : '',
                },
                phone : {
                    title : 'Phone',
                    placeHolder : '',
                },
                email:{
                    title : "Email",
                    placeHolder : '',
                },
                deliveryAppointmentRequired:{
                    title:'Delivery Appointment Required?',
                    placeHolder:''
                }
            },
            additionalPickupServices : {
                title : 'Additional Pickup Services',
                additionalInsurance : {
                    title : 'Additional Insurance',
                    placeHolder : '',
                },
                afterHoursPickup : {
                    title : 'After Hours Pickup',
                    placeHolder : '',
                },
                dropTrailer : {
                    title : 'Drop Trailer',
                    placeHolder : '',
                },
                freezeProtection : {
                    title : 'Freeze Protection',
                    placeHolder : '',
                },
                insidePickup : {
                    title : 'Inside Pickup',
                    placeHolder : '',
                },
                liftGate : {
                    title : 'Lift Gate',
                    placeHolder : '',
                }
            },	
            additionalDeliveryServices : {
                title : 'Additional Delivery Services',
                dropTrailer: {
                    title : 'Drop Trailer',
                    placeHolder : '',
                },
                liftGate : {
                    title : 'Lift Gate',
                    placeHolder : '',
                },
                insideDelivery : {
                    title : 'Inside Delivery',
                    placeHolder : '',
                }
            },
            inbond : {
                title : 'In Bond',
                inbondNumber : {
                    title : 'In Bond Number *',
                    placeHolder : '# Inbond',
                },	
                inbondStatus : {
                    title : 'Status',
                    placeHolder : '',
                },	
                pendingText : {
                    title : 'Pending',
                    placeHolder : '',
                },
                cancel : {
                    title : 'Cancel',
                    placeHolder : '',
                },
                save : {
                    title : 'Save',
                    placeHolder : '',
                },
                add : 'Add Item',
                delete:{
                    title : 'Delete'
                },
                confirmDialog : {
                    title : 'Are you sure do you want to delete this?',
                    cancel : 'Cancel',
                    confirm : 'Confirm'
                }
            },
            hazMat : {
                title : 'HazMat',
                unNumber : {
                    title : 'UNF Number',
                    placeHolder : '',
                },
                properShippingName : {
                    title : 'Proper Shipping Name',
                    placeHolder : '',
                },
                hazardClass : {
                    title : 'Hazard Class',
                    placeHolder : '',
                },
                packingGroup : {
                    title : 'Packing Group',
                    placeHolder : '',
                },
                nMFCClass : {
                    title : 'NMFC Class',
                    placeHolder : '',
                },
                emergencyContactNumber : {
                    title : 'Emergency Contact Number',
                    placeHolder : '',
                },
                contactMessage : {
                    title : 'Contact Message',
                    text : 'For Hazmat shipments, call ProTrans directly at 888-744-7669'
                }
            },
            addHandlingUnits : {
                title : 'Add Handling Units',
                addButton : {
                    title : '',
                    placeHolder : '',
                },
                qty : {
                    title : 'Quantity',
                    placeHolder : '',
                },
                type : {
                    title : 'Type',
                    placeHolder : '',
                },
                weight : {
                    title : 'Unit Weight',
                    placeHolder : '',
                },
                nmfc : {
                    title : 'Nmfc Class',
                    placeHolder : '',
                },
                diameter : {
                    title : 'Diameter',
                    placeHolder : '',
                },
                length : {
                    title : 'Length',
                    placeHolder : '',
                },
                height : {
                    title : 'Height',
                    placeHolder : '',
                },
                width : {
                    title : 'Width',
                    placeHolder : '',
                },
                message:{
                    title:"Value must be between 1 and 9999."
                }
            },	
            addReference : {
                title : 'Add Reference',
                add : 'Add Item',
                referenceType : {
                    title : 'Reference Type',
                    placeHolder : 'Choose a ref type',
                },	
                referenceValue : {
                    title : 'Reference Value',
                    placeHolder : '',
                },
                image : {
                    title : 'Image',
                    placeHolder : '',
                    viewImage : 'View Image',
                    addImage:'Add Image'
                },
                createdDate : {
                    title : 'Created Date',
                    placeHolder : '',
                },
                createdBy : {
                    title : 'Created By',
                    placeHolder : '',
                },
                printBOL : {
                    title : 'Print BOL',
                    placeHolder : '',
                },	
                delete : {
                    title : 'Delete Reference',
                    placeHolder : '',
                },
                confirmDialog : {
                    title : 'Are you sure do you want to delete this?',
                    cancel : 'Cancel',
                    confirm : 'Confirm'
                }	
            },       
            shipmentNotes : {
                title : 'Shipment Notes',
            },
            saveButtons : {
                title : '',
                submit : {
                    title : 'Submit Order',
                    placeHolder : '',
                },
                submitBOL : {
                    title : 'Submit Order & print BOL',
                    placeHolder : '',
                },
            },
            clearOrder:{
                title:"Clear Order",
                placeHolder: '',
            }
        }
    },
    partReport : {
        title : "Part Report",
        partNumber : "Part Number",
        deliveredInLastThreeDays : {
            title : "Delivered in Last Three Days",
            columns : {
                shipment : "Shipment",
                deliveryDate : "Delivery Date",
                deliveryTime : "Delivery Time",
                qtyDelivered : "Qty Delivered",
                shipFrom : "Ship From",
                pULNumber : "PUL Number",
                tripId : "Trip Id"
            }
        },
        inTransitShipments : {
            title : "In Transit Shipments",
            columns : {
                shipment : "Shipment",
                etaDate : "Eta Date",
                etaTime : "Eta Time",
                qtyDelivered : "Qty Delivered",
                shipFrom : "Ship From",
                pULNumber : "PUL Number",
                tripId : "Trip Id"
            }
        },
        plannedShipments : {
            title : "Planned Shipments",
            columns : {
                shipment : "Shipment",
                pickupDate : "Pickup Date",
                puTime : "PU Time",
                qtyDelivered : "Qty Delivered",
                shipFrom : "Ship From",
                pULNumber : "PUL Number",
                tripId : "Trip Id"
            }
        },
    }, fileUpload:{
        fileSizeExceeded: "File size exceeded",
        selectFile:'No File upload or invalid file type'
    },
    rateShop: {
        customer: "Customer",
        billTo: "Bill To",
        reset: "Reset",
        rate: "Get Rates",
        change: "Change",
        rateInquiry: "Rate Inquiry",
        origin: "Origin",
        destination: "Destination",
        postalCode: "Postal Code",
        country: "Country",
        state: "State",
        city: "City",
        pickupDeliveryDateandTerms: "Pickup/Delivery Date and Terms",
        pickupDate: "Pickup Date",
        deliveryDate: "Delivery Date",
        paymentTerms: "Payment Terms",
        equipmentType: "Equipment Type",
        specialServices: "Special Services",
        freight: "Freight",
        nmfcClass: "NMFC Class",
        type: "Type",
        length: "Length (in)",
        width: "Width (in)",
        height: "Height (in) ",
        qty: "Qty",
        diameter: "Diameter (in)",
        weight: "Weight (lb)",
        carrier: "Carrier",
        contract: "Contract",
        mode: "Mode",
        serviceDays: "Service Days",
        distance: "Distance",
        total: "Total",
        customerContract: "Customer Contract",
        customerCarrier: "Customer Carrier",
        cutomerTotal: "Customer Total",
        clear: "Clear",
        rates: "Rates",
        chooseCustomer: "Choose Customer",
        chooseBillTo: "Choose Bill To",
        message: "The rate you select is based on the information you enter in the TMS. Shipments are subject to inspection upon reciept. If the shipment's physical characteristics or specifications do not match information entered during booking, your rate may vary. Please contact load@protrans.com with questions.",
        choose: "Choose",
        save: "Save",
        noData: "No Data",
        Search: "Search",
        Noratesfound: "No rates found.",
        messages:{
            m1: "Customer and Bill To are required.",
            m2: "Customer is required",
            m3: "Bill To is required.",
            m4: "Postal code is required.",
            m5: "Validating...",
            m6: "There are some missing field that are required",
            m7: "Delivery Date should not less than Pickup Date"
        }
    },
    shipmentrequest:{
        shipmentRequest:"Shipment Request",
        customer:"Customer ",
        billTo:{
            title:"Bill To",
            Placeholder:"Choose BillTo"},
        general:"General",
        responsibleLocation:"Responsible Location*",
        shipmentreasonDetail:"Shipment Reason Detail",
        specialInstruction:"Shipment Reason Detail /Special Instruction",
        customerReferences:"Customer Reference #",
        dochHigh:"Doc High",
        responsibleparty:"Responsible Party",
        modepickupdeliveryInformation : {
            title : 'Mode, Pickup and Delivery Information',
            deliverydate:"Delivery Date",
            readytime:"Ready Time",
            closetime:"Close Time"
        },
        comment:"Comments",
        submitshipmentRequest:"Submit Shipment Request",
        clearshipmentRequest:"Clear Shipment Request",
        shipmentStatus: "Shipment Status",
        requested: "Requested",
        validationMessages:{
            m1: "ShipFrom/ShipTo are required.",
            m2: "You entered an invalid requestor number, please change for a valid one.",
            m3: "The 'Shipper - Ready Date' field is required.",
            m4: "Responsible location is required.",
            m5: "The 'Shipper - Pickup Ready Date' field is required.",
            m6: "The 'Shipper - Pickup Ready Date' field is required.",
            m7: "The 'Shipper - Pickup Ready Time' field is required(valid format from 00:00 to 23:59).",
            m8: "The 'Shipper - Pickup Close Time' field is required(valid format from 00:00 to 23:59).",
            m9: "Shipper - Close Time must be greater than Shipper - Ready Time.",
            m10: "The 'Shipper - Delivery Date' field is required.",
            m11: "The 'Shipper - Delivery Ready Time' field is required(valid format from 00:00 to 23:59).",
            m12: "The 'Shipper - Delivery Close Time' field is required(valid format from 00:00 to 23:59).",
            m13: "Shipper - Delivery Close Time must be greater than Shipper - Delivery Ready Time.",
            m14: "Shipper - Delivery Ready Time must be greater than Shipper - Pickup Close Time.",
            m15: "Duplicate handling unit type found, please update existing handling unit.",
            m16: "Reference is required.",
            m17: "Duplicate reference type was found or reference type was empty.",
            m18: "You must confirm at least one Handling Unit.",
            m19: "Value for Quantity / Weight has to be greater than 0.",
            m20: "Invalid Dimensions of HU on row #",
            m21: "Value for Length / Width / Height / Diameter cannot be zero on row #",
            m22: "The Volume of the Handling Unit is Greater Than the Maximum Volume Allowed.",
            m23: "Thank You For Your Shipment Request. An email confirmation will be sent in 10-15 minutes to",
            m24: "Please select the country for requestor phone.",
            m25: "Please enter the phone number for requestor.",
            m26:"Delivery Date is lesser than Pickup Date.",
            m27:"The duplicate reference type was found, please select another reference type."
        }
    },
    Customerbillto:{
        billTo:{
            title:"Bill To",
            Placeholder:"Choose Bill To"
        },
        customer:"Customer ",
        Continue:"Continue",
        clearOrder: "Clear Order",
        message1:"doesn't have any setting.",
        message2: " Are you sure you want to create a shipment with the following Origin/Destination? "
    },
    heatMap:{
        delimetedComas:"Type Comma-delimited Values",
        modalTitle: "Filters",
        pulNumber:"PUL Number:",
        tripId:"TripId:",
        dueDate:"Due Date:",
        etaData:"ETA Date:",
        distanceToDest:"Distance to Dest:",
        miles:"mi.",
        currentLocation:"Current Location:",
        currentDate:"Current Date",
        origin:"Origin:",
        destination:"Destination:",
        back:"Back",
        billTo:"Bill To",
        shipmentsLocation:"shipments at this location",
        appliedFilters:"Applied Filters",
        choose:"Choose",
        ediCode:"EDI Code",
        search:"Search",
        safNumber:"SAF Number",
        pickUpDate:"Pickup Date",
        deliveryDate:"Delivery Date",
        startDate:"Start Date",
        endDate:"End Date",
        load:"Load",
        isRequired:"is required",     
        warning_LoadRefRequired:"Load reference is required",
        warning_LoadRefValueRequired:"Load reference value is required",
        warning_DuplicateStatus:"Duplicate status can't be added",
        warning_DuplicateMode:"Duplicate mode can't be added",
        warning_DuplicateValue:"Duplicate value for {replace} can't be added",
        warning_DuplicateSearchBy:"Duplicate {replace} in searchBy can't be added",
        warning_ShipmentRef:"Shipment reference is required",
        warning_ShipmentRefValue:"Shipment reference value is required",
        warning_IsRequired:"{replace} is Required",
        warning_EndDate:"EndDate should greater than startDate.",
        warning_SetDate:"Please provide at least one date.",
        error_DuplicateSaf:"Duplicate safNumber in searchBy can't be added",
        error_DuplicateLoadId:"Duplicate loadId in searchBy can't be added",
        error_Route:"Route unavailable.",
        error_IntransitAssigned:"Accepts intransit or assigned orders only.",
        resultsCount:"Results Count:",
        loading:"Loading...",
        searchBy:{
            title:"Search By",
            placeHolder:"Choose",
            label:"Search By Value",
        },
        shipmentReference:{
            title:"Shipment Reference",
            placeHolder:"Choose Shipment Reference",
            label:"Shipment Reference Value"
        },
        modes:{
            title:"Modes",
            placeHolder:"Choose Mode"
        },
        status:{
            title:"Status",
            placeHolder:"Choose Status"
        },
        loadReference:{
            title:"Load Reference",
            placeHolder:"Choose Load Reference",
            label:"Load Reference Value"
        },closeModal:"Close",
        unavailable:"Unavailable"
    },internationalPhone:{
        selectCountry:'Select Country'
    }
}

export default en;