const GET_TILES = '/Dashboard/SupplierPortalDashboard/GetTiles';
const GET_TILE = '/Dashboard/SupplierPortalDashboard/getTile';
const GET_CUSTOMER_REFERENCE_TYPES = '/Dashboard/SupplierPortalDashboard/GetCustomerReferenceTypes';
const GET_ANYREFERENCES = '/Dashboard/SupplierPortalDashboard/GetAnyReferenceTypes';
const GET_STATUSES = '/Dashboard/SupplierPortalDashboard/GetCodeListLookUpData';
const GET_LOADNUMBERS = '/Dashboard/SupplierPortalDashboard/GetLoadNumbers';
const GET_SAFNUMBERS = '/Dashboard/SupplierPortalDashboard/GetSafTrackingNumbers';
const GET_ORDERSTATUS = '/Dashboard/SupplierPortalDashboard/GetOrderStatus';
const GET_SHIPMENT_HANDLING_UNITS = '/Dashboard/SupplierPortalDashboard/GetShipmentHandlingUnits';
const GET_SHIPMENT_EVENT_LOG = '/Dashboard/SupplierPortalDashboard/GetShipmentEventLog';
const GET_SHIPMENT_NOTES = '/Dashboard/SupplierPortalDashboard/GetShipmentNotes';
const GET_SHIPMENT_REFERENCES = '/Dashboard/SupplierPortalDashboard/GetShipmentReferences';
const GET_SHIPMENT_PART_INFO = '/Dashboard/SupplierPortalDashboard/GetShipmentPartInfo';
const UPDATE_HANDLING_UNIT = '/Dashboard/SupplierPortalDashboard/UpdateHandlingUnit';
const SAVE_NOTES_SHIPMENT_DETAIL = '/Dashboard/SupplierPortalDashboard/SaveNoteShipmentDetail';
const ADD_UPDATE_SHIPMENT_REFERENCE = '/Dashboard/SupplierPortalDashboard/AddOrUpdateShipmentReference';
const SHIPMENT_REFERENCE_TO_IMAGE = '/Dashboard/SupplierPortalDashboard/LinkShipmentReferenceToImage';
const ATTACH_SHIPMENT_REFERENCE = '/Dashboard/SupplierPortalDashboard/AttachFileToLocalFolder';
const REMOVE_SHIPMENT_REFERENCE = '/Dashboard/SupplierPortalDashboard/RemoveShipmentReference';
const GET_DOCUMENT_LASERFICHE = '/Dashboard/SupplierPortalDashboard/GetDocumentsFromLaserFiche';
const PRINT_BOL = "/Reports/Logistics/BillOfLadingReport?orderIds=";
const PRINT_PUL = "/Reports/Logistics/PickupListReport?orderIds=";
const PRINT_SHIPPINGLABel = "/Reports/Logistics/DefaultShippingLabelReport?orderIds=";
const DOCUMENT_VIEWER_LASERFICHE = '/Documents/DocumentDelivery/RetrieveFile';
const DOCUMENT_VIEWER_LASERFICHE_OTHERFORMAT = '/Documents/LaserficheDocumentDelivery/RetrieveFile';
const REMOVE_HANDLING_UNIT = '/Dashboard/SupplierPortalDashboard/RemoveHandlingUnit';
const GET_SCHEDULEDDELIVERIES = "/Dashboard/SupplierPortalDashboard/GetSupplierPortalScheduledDeliveries";
const GET_INTRANSIT = "/Dashboard/SupplierPortalDashboard/GetSupplierPortalInTransitShipments";
const GET_Expedites = "/Dashboard/SupplierPortalDashboard/GetSupplierPortalExpeditedShipments";
const GET_SCHEDULEDPICKUPS = "/Dashboard/SupplierPortalDashboard/GetSupplierPortalScheduledPickups";
const GET_ENTITYSELECTORSEARCHHEADER = "/Dashboard/SupplierPortalDashboard/EntitySelectorSearchHeader";
const GET_PRODUCTIONPLANNING = "/Dashboard/SupplierPortalDashboard/GetSupplierPortalProductionPlanning";
const GET_PRODUCTIONPLANNING_SCHEDULEPICKUPS = "/Dashboard/SupplierPortalDashboard/GetProductionPlanningScheduledPickupDetails";
const GET_PRODUCTIONPLANNING_INTRANSITS = "/Dashboard/SupplierPortalDashboard/GetProductionPlanningIntransitDetails";
const GET_IPI_PRO = "/Dashboard/SupplierPortalDashboard/GetIPIPro";
const PROCESS_PUL_CONFIRMATION ="/Dashboard/SupplierPortalDashboard/ProcessPULConfirmation";
const GET_IS_EDITABLE_HU = "/Dashboard/SupplierPortalDashboard/GetIsHandlingUnitEditable";
const ATTACH_FILE_LOCAL_FOLDER_ORDER = '/Dashboard/SupplierPortalDashboard/AttachFileToLocalFolderOrder';
const GET_SHIPMENT_ITEMS_FROM_CUSTOMER_HIERARCHY = '/Dashboard/SupplierPortalDashboard/GetShipmentItemsFromCustomerHierarchy';
const GET_ACTIVE_SHIPMENTS_LOCATIONS = '/Dashboard/SupplierPortalDashboard/GetActiveShipmentsLocations';
const GET_ACTIVE_SHIPMENTS_BY_GEOCOORDINATES = '/Dashboard/SupplierPortalDashboard/GetActiveShipmentsByGeoCoordinates';
const GET_COUNTRIES_FROM_GEOGRAPHICAL_BOUNDARIES = '/Dashboard/SupplierPortalDashboard/GetCountriesFromGeographicalBoundries'

export default {
    GET_TILES,
    GET_TILE,
    GET_CUSTOMER_REFERENCE_TYPES,
    GET_SHIPMENT_HANDLING_UNITS,
    GET_SHIPMENT_EVENT_LOG,
    GET_SHIPMENT_NOTES,
    GET_SHIPMENT_REFERENCES,
    GET_SHIPMENT_PART_INFO,
    UPDATE_HANDLING_UNIT,
    SAVE_NOTES_SHIPMENT_DETAIL,
    ADD_UPDATE_SHIPMENT_REFERENCE,
    SHIPMENT_REFERENCE_TO_IMAGE,
    ATTACH_SHIPMENT_REFERENCE,
    REMOVE_SHIPMENT_REFERENCE,
    GET_DOCUMENT_LASERFICHE,
    PRINT_BOL,
    PRINT_PUL,
    PRINT_SHIPPINGLABel,
    DOCUMENT_VIEWER_LASERFICHE,
    DOCUMENT_VIEWER_LASERFICHE_OTHERFORMAT,
    REMOVE_HANDLING_UNIT,
    GET_SCHEDULEDDELIVERIES,
    GET_INTRANSIT,
    GET_Expedites,
    GET_SCHEDULEDPICKUPS,
    GET_ENTITYSELECTORSEARCHHEADER,
    GET_PRODUCTIONPLANNING,
    GET_PRODUCTIONPLANNING_SCHEDULEPICKUPS,
    GET_PRODUCTIONPLANNING_INTRANSITS,
    GET_IPI_PRO,
    PROCESS_PUL_CONFIRMATION,
    GET_IS_EDITABLE_HU,
    ATTACH_FILE_LOCAL_FOLDER_ORDER,
    GET_SHIPMENT_ITEMS_FROM_CUSTOMER_HIERARCHY,
    GET_ACTIVE_SHIPMENTS_LOCATIONS,
    GET_ANYREFERENCES,
    GET_STATUSES,
    GET_LOADNUMBERS,
    GET_SAFNUMBERS,
    GET_ACTIVE_SHIPMENTS_BY_GEOCOORDINATES,
    GET_ORDERSTATUS,
    GET_COUNTRIES_FROM_GEOGRAPHICAL_BOUNDARIES
}