
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

declare global {
    interface Window { gbl_React_App_URL: any; gbl_Api_App_URL: any; gbl_IPI_Api_URL: any; gbl_BusinessIntelligence_URL: any; gbl_ShowIPITile: boolean; gbl_ShowBusinessIntelligence_Tile: boolean; gbl_ShowProductionPlanning_Tile: boolean;}
}
ReactDOM.render(<App />, document.getElementById('root'));

