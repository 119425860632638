import React from 'react';
import PTRUI, { useLocation } from 'protrans-react-ui';
import useI18N from './hooks/use-i18n';

interface Props {
    onMount: () => void;
}
export default function Config({ onMount }: Props) {
    const config = PTRUI.useConfig();
    const i18n = useI18N();
    const location = useLocation();

    React.useEffect(() => {

        //app name
        config.setName(i18n.localizeData.title);

        //logo that will appear in header and splash screens
        config.setLogoType('optimiz');

        //app version
        config.setVersion('2.0.1');

        //enable language switcher
        config.enableLanguageSwitcher(true);

        //disable SSO login
        config.disableSSOLogin(false);

        //sets global fetch credentials for useFetch
        // config.setAPIFetchCredentials({
        //     default: 'include'
        // });

        // //sets global fetch credentials for useFetch
        // config.setBaseHeaders(`{
        //     "Content-Type" : "application/json"
        // }`);

        //sets base url types
        // config.setAPIBaseURLTypes({
        //     default: window?.gbl_Api_App_URL || ''
        // });
        console.log(window?.gbl_Api_App_URL);
        config.setFetchProfiles({
            default : {
                baseURL:window?.gbl_Api_App_URL || '',
                headers: {"Content-Type": "application/json"},
                credentials: 'omit'
            },
            auth:{
               baseURL:window?.gbl_Api_App_URL || '',
               credentials:"include"
            }
        })
        //collapse side bar on page load by path
        config.setSideBarCollapsePaths(true);
        

        //sets redirect for non authorized users
        //config.setNoAuthRedirectURL(window.gbl_React_App_URL + '/auth');

        //sets redirect when user logouts
        //config.setLogoutRedirectURL('/auth/forceLogOff');
        config.setLogoutRedirectURL('http://preprod.optimiz.platform.dptsprotrans.com/LogOn?ReturnUrl=%2F');

        config.setClientId("ProTrans_React_CSP");
        config.setRedirectUrl(window.gbl_React_App_URL);

    }, [i18n.localizeData.title]);

    //makes sure start up does not fire until after config mounts
    React.useEffect(() => {
        onMount();
    }, []);

    return null;
}