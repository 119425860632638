import React from "react";
import PTRUI, { Route, Lazy, Outlet, lazyImport } from 'protrans-react-ui';

const Home = PTRUI.lazyImport(() => import('./scenes/home'));

/* Scheduled pickup */
const ScheduledPickups = PTRUI.lazyImport(() => import('./scenes/scheduled-pickups'));
// /*Intranist */
const IntransitShipments = PTRUI.lazyImport(() => import('./scenes/intransit'));
// /*Scheduled Deliveries */
const ScheduledDeliveries = PTRUI.lazyImport(() => import('./scenes/scheduled-deliveries'));
// /*Expedite Shipments */
const ExpediteShipments = PTRUI.lazyImport(() => import('./scenes/expedites'));
// /*Expedite Shipments */
const CreateOrder = PTRUI.lazyImport(() => import('./scenes/create-order'));

const ProductionPlanning = PTRUI.lazyImport(()=> import('./scenes/production-planning'));
/*Rate Shop*/
const RateShop = lazyImport(() => import('./scenes/rate-shop'));

// /*Heat Map*/
const HeatMap = PTRUI.lazyImport(() => import('./scenes/heat-map'));

/* Shipment Request */
const ShipmentRequest = PTRUI.lazyImport(()=>import('./scenes/shipment-request'));

//const AlternativeLoginPage = PTRUI.lazyImport(() => import("../components/app-config/login-form/alternative-login-page"));

export default function Routes() {

  return (
    <>
      <Route index element={<Lazy fallback={'landing'}><Home /></Lazy>} />
      <Route path={'scheduled-pickups'} element={<Lazy fallback={'tableGrid'}><ScheduledPickups /></Lazy>}>
        <Route path={':subTile'} element={<Lazy fallback={'tableGrid'}><ScheduledPickups /></Lazy>} />
      </Route>
      <Route path={'intransit'} element={<Lazy fallback={'tableGrid'}><IntransitShipments /></Lazy>}>
        <Route path={':subTile'} element={<Lazy fallback={'tableGrid'}><IntransitShipments /></Lazy>} />
      </Route>
      <Route path={'scheduled-deliveries'} element={<Lazy fallback={'tableGrid'}><ScheduledDeliveries /></Lazy>}>
        <Route path={':subTile'} element={<Lazy fallback={'tableGrid'}><ScheduledDeliveries /></Lazy>} />
      </Route>
      <Route path={'scheduled-pickups'} element={<Lazy fallback={'tableGrid'}><ScheduledDeliveries /></Lazy>}>
        <Route path={':subTile'} element={<Lazy fallback={'tableGrid'}><ScheduledDeliveries /></Lazy>} />
      </Route>
      <Route path={'expedites'} element={<Lazy fallback={'tableGrid'}><ExpediteShipments /></Lazy>}>
        <Route path={':subTile'} element={<Lazy fallback={'tableGrid'}><ExpediteShipments /></Lazy>} />
      </Route>
      <Route path={'create-order'} element={<Lazy fallback={'basic'}><CreateOrder /></Lazy>}>  </Route>
      <Route path={'production-planning'} element={<Outlet />}>
        <Route path={''} element={<Lazy fallback={'tableGrid'}><ProductionPlanning /></Lazy>} />
      </Route>
      <Route path={'rate-shop'} element={<Lazy fallback={'basic'}><RateShop/></Lazy>}></Route>
      <Route path={'heat-map'} element={<Lazy fallback={'basic'}><HeatMap /></Lazy>}></Route>
      <Route path={'shipment-request'} element={<Lazy fallback={'basic'}><ShipmentRequest /></Lazy>}></Route>
    </>
  );
};

