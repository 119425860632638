import React from 'react';
import { SideNav } from 'protrans-react-ui';
import useI18N from './hooks/use-i18n';
import useStore from './hooks/use-store';
import { TileInfo } from './common/_types/tiles-types';
import { useLocation } from 'protrans-react-ui';
import { Modal } from 'protrans-react-ui';
import CustomerBillToModal from './scenes/home/customer-billto-modal';

export default function Navigation() {
    const i18n = useI18N();
    const store = useStore();
    const location = useLocation();    
    const {customerOrSupplier, userData} = store;
    const { portalConfiguration } = userData;
    const {createOrder:{title:titleCreateOrder}} = i18n.localizeData.landingPage.tiles
    const {scheduledPickups:{title: titleScheduledPickups, subTiles:{total: totalScheduledPickups, scheduled:pickupScheduledPickups, onTime:onTimeScheduledPickups, late:lateScheduledPickups}} }= i18n.localizeData.landingPage.tiles;
    const {inTransit:{title: titleInTransit, subTiles:{total: totalInTransit, onTime:onTimeInTransit, late:lateInTransit}} }= i18n.localizeData.landingPage.tiles;
    const {scheduledDeliveries:{title: titleScheduledDeliveries, subTiles:{total: totalScheduledDeliveries, onTime:onTimeScheduledDeliveries, delivered:deliveredScheduledDeliveries, late:lateScheduledDeliveries}} }= i18n.localizeData.landingPage.tiles;
    const {expedites:{title: titleExpedites, subTiles:{total: totalExpedites, scheduled:scheduledExpedites, delivered:deliveredExpedites, late:lateExpedites}} }= i18n.localizeData.landingPage.tiles;
    const tileSidebarInfo =store?.tileSidebarInfo as TileInfo[];    
    const scheduledPickups = tileSidebarInfo?.find((item: TileInfo)=> (item.Id === 30));
    const inTransint = tileSidebarInfo?.find((item: TileInfo)=> (item.Id === 20));
    const scheduledDeliveries = tileSidebarInfo?.find((item: TileInfo)=> (item.Id === 40));
    const expedites = tileSidebarInfo?.find((item: TileInfo)=> (item.Id === 80));
    const {rateShop:{title:titleRateShop}} = i18n.localizeData.landingPage.tiles;
    const {billTo,customer} = i18n.localizeData.Customerbillto;

    return (
        customerOrSupplier ? 
          <>
          <SideNav>
                    <SideNav.Item
                        icon={<i className="fad fa-clipboard-list-check"></i>}
                        text={i18n.localizeData.landingPage.tiles.createOrderAndSR.title}
                        hide={!store.showCreateOrderAndSRIcon}
                        onClick={()=> {
                                store.updateCustomerModalState(true)
                        }}
                    //    path={'/'}
                        isActive={location.pathname === "/shipment-request" || location.pathname === "/create-order"}
                    />
                <SideNav.Section text={i18n.translateThis(i18n.localeKey, titleScheduledPickups)} icon={<i className="fad fa-truck"></i>}>
                    <SideNav.Item
                        icon={<></>}
                        text={i18n.translateThis(i18n.localeKey, totalScheduledPickups)}
                        path={'/scheduled-pickups'}
                        badge={scheduledPickups?.TotalCounter ?? 0}
                    />
                    <SideNav.Item
                        icon={<></>}
                        text={i18n.translateThis(i18n.localeKey, pickupScheduledPickups)}
                        path={'/scheduled-pickups/pending'}
                        badge={scheduledPickups?.OnTimeCounter ?? 0}
                    />
                    <SideNav.Item
                        icon={<></>}
                        text={i18n.translateThis(i18n.localeKey, onTimeScheduledPickups)}
                        path={'/scheduled-pickups/ontime'}
                        badge={scheduledPickups?.PickedUpCounter ?? 0}
                    />
                    <SideNav.Item
                        icon={<></>}
                        text={i18n.translateThis(i18n.localeKey, lateScheduledPickups)}
                        path={'/scheduled-pickups/late'}
                        badge={scheduledPickups?.LateCounter ?? 0}
                    />
                </SideNav.Section>
                <SideNav.Section text={i18n.translateThis(i18n.localeKey, titleInTransit)} icon={<i className="fad fa-truck-loading"></i>}>
                    <SideNav.Item
                        icon={<></>}
                        text={i18n.translateThis(i18n.localeKey, totalInTransit)}
                        path={'/intransit'}
                        badge={inTransint?.TotalCounter ?? 0}
                    />
                    <SideNav.Item
                        icon={<></>}
                        text={i18n.translateThis(i18n.localeKey, onTimeInTransit)}
                        path={'/intransit/ontime'}
                        badge={inTransint?.OnTimeCounter ?? 0}
                    />
                    <SideNav.Item
                        icon={<></>}
                        text={i18n.translateThis(i18n.localeKey, lateInTransit)}
                        path={'/intransit/late'}
                        badge={inTransint?.LateCounter ?? 0}
                    />
                </SideNav.Section>
                <SideNav.Section text={i18n.translateThis(i18n.localeKey, titleScheduledDeliveries)} icon={<i className="fad fa-calendar-day"></i>}>
                    <SideNav.Item
                        icon={<></>}
                        text={i18n.translateThis(i18n.localeKey, totalScheduledDeliveries)}
                        path={'/scheduled-deliveries'}
                        badge={scheduledDeliveries?.TotalCounter ?? 0}
                    />
                    <SideNav.Item
                        icon={<></>}
                        text={i18n.translateThis(i18n.localeKey, onTimeScheduledDeliveries)}
                        path={'/scheduled-deliveries/ontime'}
                        badge={scheduledDeliveries?.OnTimeCounter ?? 0}
                    />
                    <SideNav.Item
                        icon={<></>}
                        text={i18n.translateThis(i18n.localeKey, lateScheduledDeliveries)}
                        path={'/scheduled-deliveries/late'}
                        badge={scheduledDeliveries?.LateCounter ?? 0}
                    />
                    <SideNav.Item
                        icon={<></>}
                        text={i18n.translateThis(i18n.localeKey, deliveredScheduledDeliveries)}
                        path={'/scheduled-deliveries/delivered'}
                        badge={scheduledDeliveries?.DeliveredCounter ?? 0}
                    />
                </SideNav.Section>
                <SideNav.Section text={i18n.translateThis(i18n.localeKey, titleExpedites)} icon={<i className="fad fa-shipping-fast"></i>}>
                    <SideNav.Item
                        icon={<></>}
                        text={i18n.translateThis(i18n.localeKey, totalExpedites)}
                        path={'/expedites'}
                        badge={expedites?.TotalCounter ?? 0}
                    />
                    <SideNav.Item
                        icon={<></>}
                        text={i18n.translateThis(i18n.localeKey, scheduledExpedites)}
                        path={'/expedites/scheduled'}
                        badge={expedites?.PendingCounter ?? 0}
                    />
                    <SideNav.Item
                        icon={<></>}
                        text={i18n.translateThis(i18n.localeKey, deliveredExpedites)}
                        path={'/expedites/delivered'}
                        badge={expedites?.OnTimeCounter ?? 0}
                    />
                    <SideNav.Item
                        icon={<></>}
                        text={i18n.translateThis(i18n.localeKey, lateExpedites)}
                        path={'/expedites/late'}
                        badge={expedites?.LateCounter  ?? 0}
                    />
                    </SideNav.Section>
                    {
                        portalConfiguration.CanViewRateShop ?
                            (<SideNav.Item
                                icon={<i className="fa-solid fa-money-check-dollar"></i>}
                                text={i18n.translateThis(i18n.localeKey, titleRateShop)}
                                path={'/rate-shop'} />)
                            : <></>
                    }                
                    {
                        window.gbl_ShowProductionPlanning_Tile ?
                            <SideNav.Item
                                icon={<i className="fas fa-th-list"></i>}
                                text={i18n.localizeData?.landingPage.tiles.productionPlanning.title}
                                path={'/production-planning'}
                            />
                            :
                            <></>
                    }
                    {
                        portalConfiguration.EnableTrackingMap ?
                            ( <SideNav.Item
                                icon={<i className="fa-solid fa-map-location-dot"></i>}
                                text={i18n.localizeData.landingPage.tiles.heatMap.title}
                                path={'/heat-map'}
                                hide={userData?.userRolesArray?.filter((x: any) => x == "Customer")?.length > 0 ? false: true}
                            />)
                            : (<></>)
                    }
            </SideNav>
            <Modal
                title={userData?.roles == "|Customer|" ? billTo.title : customer}
                show={store.CustomerModalState}
                size={"md"}
                onHide={() => {
                    store.updateCustomerModalState(false);
                }}
                >
              <Modal.Body style={{ minHeight: "200px" }}>{userData ? <CustomerBillToModal userData={userData} /> : <>loading</>}</Modal.Body>
           </Modal>
          </>
            :
            <>
            </>
    )
};