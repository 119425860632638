import PTRUI, { Button, Col, Form, useNavigate } from "protrans-react-ui";
import React, { useEffect, useState } from "react";
import useStore from "../../../hooks/use-store";
import useI18N from "../../../hooks/use-i18n";


interface Props {
    userData: any
}
export default function CustomerBillToModal({ userData }: Props) {
    const navigate = useNavigate();
    const store = useStore();
    const message = PTRUI.useInAppMessage();
    const [customerId, setCustomerId] = useState("");
    const [customers4SupplierUser, setCustomers4SupplierUser] = useState<{ CustomerId: number; CustomerName: string }[]>([]);
    const [billToId, setBillToId] = useState("");
    const [billTos4SupplierUser, setBillTos4SupplierUser] = useState<{
        BillToId: number; BillToName: string; AllowCreateOrder: boolean;
        AllowShipmentRequest: boolean;
    }[]>([]);
    const [activeBillTo, setActiveBillTo] = useState<{ value: string; text: string; }>({ value: "", text: "" });
    const i18n = useI18N();
    const{billTo,customer,Continue,message1}=i18n.localizeData.Customerbillto


    useEffect(() => {
        if (userData?.supplierBillTosAllowedToCreateOrders.length > 0) {
            let arrCustomerAllDll: {
                CustomerId: number;
                CustomerName: string;
            }[] = userData?.supplierBillTosAllowedToCreateOrders?.map((x: any) => ({
                CustomerId: x.CustomerId,
                CustomerName: x.CustomerName,
            }));
            let arrCustomerDll: {
                CustomerId: number;
                CustomerName: string;
            }[] = arrCustomerAllDll.filter(
                (x: { CustomerId: number; CustomerName: string }, index, arr) => {
                    return (
                        arr.findIndex((t) => t.CustomerId === x.CustomerId) === index
                    );
                }
            );
            setCustomers4SupplierUser(arrCustomerDll);
        }
        if (userData?.roles == '|Customer|') {
            let arrBillTosAllDll: any[] = [];
            userData?.clientBillTosAllowedToCreateOrders?.map((x: any) => {
                userData?.billTos?.map((y: any) => {
                    if (x.BillToId == y.PartyRoleId && (x.AllowCreateOrder || x.AllowShipmentRequest)) {
                        let billTo: {
                            BillToId: number;
                            BillToName: string;
                            AllowCreateOrder: boolean;
                            AllowShipmentRequest: boolean;
                        } = {
                            BillToId: x.BillToId,
                            BillToName: y.LegalName,
                            AllowCreateOrder: x.AllowCreateOrder,
                            AllowShipmentRequest: x.AllowShipmentRequest
                        }
                        arrBillTosAllDll.push(billTo);
                    }
                });
            });
            setBillTos4SupplierUser(arrBillTosAllDll);
        }
    }, []);

    return (
        <>
            {
                userData?.roles == '|Customer|' ?
                    <div>
                        <Form.Select
                            flushMenu
                            name="billTo"
                            label={billTo.title}
                            data={billTos4SupplierUser}
                            placeHolder={billTo.Placeholder}
                            mapToText={"BillToName"}
                            mapToValue={"BillToId"}
                            onChange={(value: any) => {
                                setBillToId(value.BillToId);
                                setActiveBillTo({ value: value.BillToId, text: value.BillToName });
                                store.updateSelectedBillToDetails(billTos4SupplierUser.filter((x: any) => x.BillToId == value.BillToId)[0]);
                            }}
                            activeItem={activeBillTo.text != "" ? activeBillTo : undefined}
                        />
                    </div>
                    : <>
                        <Form.Select
                            flushMenu
                            name="customer"
                            label={customer}
                            data={customers4SupplierUser}
                            mapToText={"CustomerName"}
                            mapToValue={"CustomerId"}
                            onChange={(value: any) => {
                                let arrBillTosAllDll: {
                                    BillToId: number;
                                    BillToName: string;
                                    AllowCreateOrder: boolean;
                                    AllowShipmentRequest: boolean;
                                }[] = userData?.supplierBillTosAllowedToCreateOrders
                                    ?.filter((x: any) => x.CustomerId == value.CustomerId)
                                    ?.map((x: any) => ({
                                        BillToId: x.BillToId,
                                        BillToName: x.BillToName,
                                        AllowCreateOrder: x.AllowCreateOrder,
                                        AllowShipmentRequest: x.AllowShipmentRequest
                                    }));
                                let arrBillTosDll: {
                                    BillToId: number;
                                    BillToName: string;
                                    AllowCreateOrder: boolean;
                                    AllowShipmentRequest: boolean;
                                }[] = arrBillTosAllDll.filter(
                                    (x: {
                                        BillToId: number;
                                        BillToName: string;
                                        AllowCreateOrder: boolean;
                                        AllowShipmentRequest: boolean;
                                    }, index, arr) => {
                                        return (arr.findIndex((t) => t.BillToId === x.BillToId) === index);
                                    }
                                );
                                setCustomerId(value.CustomerId);
                                setActiveBillTo({ value: "", text: "" });
                                setBillToId("");
                                setBillTos4SupplierUser(arrBillTosDll);
                                store.updateSelectedCustomerDetails(customers4SupplierUser.filter((x: any) => x.CustomerId == value.CustomerId)[0]);
                            }}
                        />
                        <div>
                            <Form.Select
                                flushMenu
                                name="billTo"
                                label={billTo.title}
                                data={billTos4SupplierUser}
                                placeHolder={billTo.Placeholder}
                                mapToText={"BillToName"}
                                mapToValue={"BillToId"}
                                onChange={(value: any) => {
                                    setBillToId(value.BillToId);
                                    setActiveBillTo({ value: value.BillToId, text: value.BillToName });
                                    store.updateSelectedBillToDetails(billTos4SupplierUser.filter((x: any) => x.BillToId == value.BillToId)[0]);
                                }}
                                activeItem={activeBillTo.text != "" ? activeBillTo : undefined}
                            />
                        </div>
                    </>
            }
            <div>
                <Col className="text-end">
                    <Button
                        className={'mt-3'}
                        onClick={() => {
                            store.updateCustomerModalState(false);
                            let selectedBillToDetails = billTos4SupplierUser?.filter((x: any) => x.BillToId == billToId)[0];
                            if (selectedBillToDetails?.AllowCreateOrder) {
                                window.location.pathname ===  '/create-order' ?
                                    navigate('/create-order/')
                                :
                                    navigate('/create-order');
                            } else if (selectedBillToDetails?.AllowShipmentRequest) {
                                navigate("/shipment-request");
                            } else {
                                message.createInfo(selectedBillToDetails.BillToName + message1)
                            }
                        }}
                        small
                        label={Continue}
                        disabled={billToId ? false : true}
                    ></Button>
                </Col>
            </div>
        </>
    );
}